import React from "react";

const discussionTopics = [
  "Technology",
  "Climate",
  "Space exploration",
  "AI and ethics",
  "Social media",
  "Mental health",
  "Education",
  "Health",
  "Culture",
  "Politics",
  "Sports",
  "Public opinion",
  "History",
  "Economy",
  "Business",
  "Science",
  "Philosophy",
  "Art",
  "Sustainability",
  "Renewable energy",
  "Cryptocurrency",
  "Quantum computing",
  "Globalization",
  "Cybersecurity",
  "Genetics",
  "Neuroscience",
  "Human rights",
  "Entrepreneurship",
  "Innovation",
  "Space tourism",
  "Pandemics",
  "Bioethics",
  "Food security",
  "Agriculture",
  "Urban development",
  "Environmental policy",
  "Artificial life",
  "Literature",
  "Music",
  "Fashion",
  "Photography",
  "Robotics",
  "Virtual reality",
  "Augmented reality",
  "Gaming",
  "Animal welfare",
  "Astronomy",
  "Oceanography",
  "Feminism",
  "LGBTQ+ issues",
  "Cultural heritage",
  "Cognitive science",
  "Evolution",
  "Astrobiology",
  "Machine learning",
  "Blockchain",
  "E-commerce",
  "Digital marketing",
  "Social justice",
  "Environment",
  "Workplace culture",
  "Automation",
  "Transportation",
  "Public health",
  "Nutrition",
  "Wellness",
  "Aging",
  "Artificial general intelligence",
  "Digital privacy",
  "Online education",
  "Parenting",
  "Global warming",
  "Nuclear energy",
  "Deep learning",
  "Climate adaptation",
  "Data science",
  "Conflict resolution",
  "Economic inequality",
  "Disaster management",
  "Renewable materials",
  "Tech regulation",
  "Space habitats",
  "Climate justice",
  "Indigenous rights",
  "Ethical hacking",
  "Open source software",
  "Consumer behavior",
  "Sociology",
];

const hexColorCodes = [
  "#FF5733",
  "#33FF57",
  "#5733FF",
  "#FF3357",
  "#33FFB2",
  "#33B2FF",
  "#B233FF",
  "#FF33B2",
  "#B2FF33",
  "#FFB233",
  "#B233FF",
  "#33FF6F",
  "#6FFF33",
  "#336FFF",
  "#FF336F",
  "#6F33FF",
  "#33FF8C",
  "#8CFF33",
  "#FF8C33",
  "#8C33FF",
  "#33FFFF",
  "#FFFF33",
  "#33FFC2",
  "#C2FF33",
  "#FFC233",
  "#C233FF",
  "#FF9999",
  "#99FF99",
  "#9999FF",
  "#FFCC33",
  "#CCFF33",
  "#33CCFF",
  "#FF33CC",
  "#FF9966",
  "#66FF99",
  "#6699FF",
  "#FF6633",
  "#3366FF",
  "#33FF66",
  "#FF66CC",
  "#66FFCC",
  "#CC66FF",
  "#66CCFF",
  "#FFCC66",
  "#66FF66",
  "#33FF99",
  "#99FF33",
  "#3399FF",
  "#9933FF",
  "#FF3333",
  "#FF999C",
  "#FFCC99",
  "#CCCC33",
  "#FF333C",
  "#33CC33",
  "#339933",
  "#FF3399",
  "#FF9933",
  "#CC3399",
  "#33CC99",
  "#99CC33",
  "#99FF66",
  "#33FF33",
  "#993399",
  "#CC6633",
  "#6666FF",
  "#CCFF66",
  "#66CC33",
  "#669933",
  "#993366",
  "#99CCFF",
  "#33FFFF",
  "#33FFDD",
  "#DDFF33",
  "#FFDD33",
  "#33DDFF",
  "#DD33FF",
  "#99DDFF",
  "#FF99DD",
  "#DDFF99",
  "#33FF99",
  "#99FFDD",
  "#FFDD99",
  "#33DD33",
  "#DD33DD",
  "#FFDDDD",
  "#DDFF33",
  "#FFDD33",
];


const Explore = () => {
  const navigateToTopic = (topic) => {
    window.location.href = `/explore/${topic.toLowerCase()}`;
  };
  return (
    <div className="w-full md:w-[50%] text-center h-screen mt-8">
      <h1 className="text-xl text-gray-800 dark:text-white">
        {" "}
        Select A Topic To Explore
      </h1>
      <div className="grid grid-cols-3 md:grid-cols-4 mt-3 items-center">
        {discussionTopics.map((topic, index) => (
          <div
            key={index}
            className="flex items-center cursor-pointer gap-2 m-3 text-start"
            onClick={() => navigateToTopic(topic)}
          >
            <div
              className="h-2 md:w-4 w-2 md:h-4 rounded-full"
              style={{ backgroundColor: hexColorCodes[index] }}
            ></div>
            <h3 className="text-xs">{topic}</h3>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Explore;
